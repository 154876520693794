export const useCdn = false;

/**
 * Import Algolia environment variables from .env.local
 */

export const applicationId = assertValue(
  process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID,
  'Missing environment variable: NEXT_PUBLIC_ALGOLIA_APPLICATION_ID'
);

export const index = assertValue(
  process.env.NEXT_PUBLIC_ALGOLIA_INDEX_NAME,
  'Missing environment variable: NEXT_PUBLIC_ALGOLIA_INDEX_NAME'
);

export const readToken = assertValue(
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_KEY,
  'Missing environment variable: NEXT_PUBLIC_ALGOLIA_SEARCH_KEY'
);

export const writeToken = assertValue(
  process.env.NEXT_PUBLIC_ALGOLIA_ADMIN_KEY,
  'Missing environment variable: NEXT_PUBLIC_ALGOLIA_ADMIN_KEY'
);

function assertValue<T>(v: T | undefined, errorMessage: string): T {
  if (v === undefined) {
    throw new Error(errorMessage);
  }

  return v;
}
