import React from 'react';
import { AutocompleteApi } from '@algolia/autocomplete-core';

interface FormProps {
  className?: string;
  formRef: React.RefObject<HTMLFormElement>;
  inputRef: React.RefObject<HTMLInputElement>;
  autocomplete: AutocompleteApi<any, any, any, any>; // Replace 'any' with specific types as needed
  onFocus?: () => void;
  onBlur?: () => void;
}

export const SearchForm: React.FC<FormProps> = ({
  formRef,
  inputRef,
  autocomplete,
  className,
  onFocus,
  onBlur,
}) => {
  return (
    <form
      ref={formRef}
      className={`flex items-center rounded-xl border border-gray-500 bg-charcoal-700 focus-within:bg-white focus-within:shadow-md focus-within:shadow-black/10 ${className}`}
      {...autocomplete.getFormProps({ inputElement: inputRef.current })}
    >
      <div className='flex h-[44px] flex-shrink-0 items-center [order:1]'>
        <label className='aa-Label' {...autocomplete.getLabelProps({})}>
          <button
            className='h-full px-3 [&_path]:stroke-charcoal-500/50 [&_stroke]:text-charcoal-500/50'
            type='submit'
            title='Submit'
          >
            {/* SearchIcon */}
            <svg width='20' height='20' viewBox='0 0 20 20'>
              <path
                d='M14.386 14.386l4.0877 4.0877-4.0877-4.0877c-2.9418 2.9419-7.7115 2.9419-10.6533 0-2.9419-2.9418-2.9419-7.7115 0-10.6533 2.9418-2.9419 7.7115-2.9419 10.6533 0 2.9419 2.9418 2.9419 7.7115 0 10.6533z'
                fill='none'
                fillRule='evenodd'
                stroke='currentColor'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='1.4'
              />
            </svg>
          </button>
        </label>
      </div>
      <div className='relative w-full [order:3]'>
        {/* aa-Input h-[44px] appearance-none border-none bg-none text-charcoal */}
        <input
          className='h-[44px] w-full appearance-none border-none bg-transparent !bg-none p-0 text-charcoal focus:outline-none'
          ref={inputRef}
          {...autocomplete.getInputProps({
            inputElement: inputRef.current,
            onFocus,
            onBlur,
          })}
        />
      </div>
      {inputRef.current && inputRef.current.value && (
        <div className='aa-InputWrapperSuffix'>
          <button className='aa-ClearButton' title='Clear' type='reset'>
            {/* ClearIcon */}
            <svg width='20' height='20' viewBox='0 0 20 20' fill='currentColor'>
              <path
                d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                fillRule='evenodd'
                clipRule='evenodd'
              />
            </svg>
          </button>
        </div>
      )}
    </form>
  );
};
