import React from 'react';
import { SearchResults } from './SearchResults';
import { AutocompleteApi, AutocompleteState } from '@algolia/autocomplete-core';

interface PanelProps {
  className?: string;
  panelRef: React.RefObject<HTMLDivElement>;
  autocompleteState: AutocompleteState<any>; // Replace 'any' with the specific type for your items
  autocomplete: AutocompleteApi<any, any, any, any>; // Replace 'any' with specific types as needed
}

export const SearchPanel: React.FC<PanelProps> = ({
  className,
  panelRef,
  autocompleteState,
  autocomplete,
}) => {
  return (
    <div
      ref={panelRef}
      className={`absolute left-0 right-0 z-10 mt-1.5 rounded-lg border border-gray-300 bg-white shadow-lg shadow-black/10 ${className}`} // Add other class names as needed
      {...autocomplete.getPanelProps({})}
    >
      {/* Panel content, using Section component */}
      {autocompleteState.collections.map((collection, index) => (
        <SearchResults
          key={`source-${index}`}
          collection={collection}
          autocomplete={autocomplete}
        />
      ))}
    </div>
  );
};
