'use client';
import {
  AutocompleteOptions,
  AutocompleteState,
  createAutocomplete,
} from '@algolia/autocomplete-core';
import { getAlgoliaResults } from '@algolia/autocomplete-preset-algolia';
import { Hit } from '@algolia/client-search';
import algoliasearch from 'algoliasearch/lite';
import React, { useState, useRef, useEffect } from 'react';
import { SearchForm } from './SearchForm';
import { SearchPanel } from './SearchPanel';
import { applicationId, readToken } from '@/lib/algolia/client.env';
import { index } from '@/lib/algolia/client.env';
import LazyMotionWrapper from '../shared/LazyMotionWrapper';
import { getArticleUrl } from '@/lib/pathUtils';

const searchClient = algoliasearch(applicationId, readToken);

type SearchItem = Hit<{
  image: string;
  title: string;
  objectID: string;
  slug: string;
  tags: string[];
}>;

export default function BlogSearch(
  props: Partial<AutocompleteOptions<SearchItem>> & {
    onOpenChange: (isOpen: boolean) => void;
  }
) {
  const [autocompleteState, setAutocompleteState] = useState<
    AutocompleteState<SearchItem>
  >({
    collections: [],
    completion: null,
    context: {},
    isOpen: false,
    query: '',
    activeItemId: null,
    status: 'idle',
  });
  const autocomplete = React.useMemo(
    () =>
      createAutocomplete<
        SearchItem,
        React.BaseSyntheticEvent,
        React.MouseEvent,
        React.KeyboardEvent
      >({
        id: 'blogSearch',
        onStateChange({ state }) {
          setAutocompleteState(state);
          props.onOpenChange(inputRef.current === document.activeElement); // Update parent component
        },
        insights: false,
        defaultActiveItemId: 0,
        getSources() {
          return [
            {
              sourceId: 'articles',
              getItems({ query }) {
                return getAlgoliaResults({
                  searchClient,
                  queries: [
                    {
                      indexName: index,
                      query,
                      params: {
                        hitsPerPage: 5,
                      },
                    },
                  ],
                });
              },
              getItemUrl({ item }) {
                return getArticleUrl(item.slug);
              },
            },
          ];
        },
        ...props,
      }),
    [props]
  );

  const handleFocusChange = (isOpen: boolean) => {
    props.onOpenChange(isOpen);
    setAutocompleteState((prev) => ({ ...prev, isOpen: isOpen }));
  };

  const inputRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const panelRef = useRef<HTMLDivElement>(null);
  const { getEnvironmentProps } = autocomplete;

  useEffect(() => {
    if (!formRef.current || !panelRef.current || !inputRef.current) {
      return undefined;
    }

    const { onTouchStart, onTouchMove, onMouseDown } = getEnvironmentProps({
      formElement: formRef.current,
      inputElement: inputRef.current,
      panelElement: panelRef.current,
    });

    window.addEventListener('mousedown', onMouseDown);
    window.addEventListener('touchstart', onTouchStart);
    window.addEventListener('touchmove', onTouchMove);

    return () => {
      window.removeEventListener('mousedown', onMouseDown);
      window.removeEventListener('touchstart', onTouchStart);
      window.removeEventListener('touchmove', onTouchMove);
    };
  }, [getEnvironmentProps, autocompleteState.isOpen]);

  return (
    <LazyMotionWrapper>
      <div
        className='relative z-20 w-full transition-all duration-200 focus-within:w-full sm:w-[230px] sm:focus-within:w-[450px] md:w-[300px] md:focus-within:w-[500px]'
        {...autocomplete.getRootProps({})}
      >
        <SearchForm
          className='peer'
          formRef={formRef}
          inputRef={inputRef}
          autocomplete={autocomplete}
          onFocus={() => handleFocusChange(true)}
          onBlur={() => handleFocusChange(false)}
        />
        <div
          className={` ${
            autocompleteState.isOpen
              ? 'opacity-100 delay-200 duration-100'
              : 'opacity-0'
          }`}
        >
          {autocompleteState.isOpen && (
            <SearchPanel
              panelRef={panelRef}
              autocompleteState={autocompleteState}
              autocomplete={autocomplete}
            />
          )}
        </div>
      </div>
    </LazyMotionWrapper>
  );
}
