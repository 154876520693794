'use client';
import { useState } from 'react';
import SocialFollow from './SocialFollow';
import BlogSearch from '../algolia/BlogSearch';

export default function BlogSearchBar() {
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const handleSearchChange = (isOpen: boolean) => {
    setIsSearchOpen(isOpen);
  };

  return (
    <div className='group border-b border-gray-400/20 bg-charcoal-800 py-1.5'>
      <div className='container-padding container flex items-center justify-between'>
        <div
          className={`flex items-center transition-all duration-200 ${
            isSearchOpen ? 'opacity-0 lg:opacity-80' : 'opacity-100'
          }`}
        >
          <SocialFollow
            className={`${isSearchOpen ? 'absolute' : 'flex-shrink-0'}`}
          />
        </div>
        <div
          className={`${
            isSearchOpen
              ? 'w-full flex-shrink-0 sm:w-[unset] sm:flex-shrink'
              : ''
          }`}
        >
          <BlogSearch
            onOpenChange={handleSearchChange}
            placeholder='Search'
            openOnFocus={true}
          />
        </div>
      </div>
    </div>
  );
}
